@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  font-family: 'SpaceGrotesk', sans-serif;
  font-weight: 300;
}

body {
  background: #090909;
}

@screen ms {
  html {
    font-size: 16px;
  }
}

@screen mm {
  html {
    font-size: 16px;
  }
}

@screen ml {
  html {
    font-size: 16px;
  }
}

@screen t {
  html {
    font-size: 16px;
  }
}

@screen dxs {
  html {
    font-size: 16px; 
  }
}

@screen ds {
  html {
    font-size: 16px;
  }
}

@screen dm {
  html {
    font-size: 16px;
  }
}

@screen dl {
  html {
    font-size: 16px;
  }
}

@screen dxl {
  html {
    font-size: 16px;
  }
}


@font-face {
  font-family: 'SpaceGrotesk';
  src: url('./resources/fonts/SpaceGrotesk/SpaceGrotesk-Light.ttf')
    format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SpaceGrotesk';
  src: url('./resources/fonts/SpaceGrotesk/SpaceGrotesk-Regular.ttf')
    format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SpaceGrotesk';
  src: url('./resources/fonts/SpaceGrotesk/SpaceGrotesk-Medium.ttf')
    format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SpaceGrotesk';
  src: url('./resources/fonts/SpaceGrotesk/SpaceGrotesk-SemiBold.ttf')
    format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SpaceGrotesk';
  src: url('./resources/fonts/SpaceGrotesk/SpaceGrotesk-Bold.ttf')
    format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@layer utilities {
  .shadow-btn {
     box-shadow: 0px 0px 0px 4px rgba(254, 39, 59, 0.3);
  }
  .shadow-blur {
    box-shadow: 0px 0px 150px 50px #ff00004f;
  }
  .shadow-tooltipFilter {
    filter: drop-shadow(0px 1px 2px rgb(14 14 14 / 0.55));
  }
  .line {
    fill: none;
    stroke-width: 1;
    stroke: #5b1a23;
    outline:none;
    stroke-dasharray: 4;
  }
  .disable-scroll {
    overflow: hidden;
    height: 100vh;
    width: 100vw;
  }
}

::-webkit-scrollbar {
  background: transparent;
  width: 4px;
  height: 4px;
  border: none;
  cursor: pointer;
}
::-webkit-scrollbar-thumb {
  background: #646464;
  border-radius: 2px;
  border: none;
  cursor: pointer;
}
